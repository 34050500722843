<template>
	<div class="reservation">
		<section class="reservation-top-block pt-4">
			<div class="reservation-content flex flex-col xl:flex-none xl:grid grid-cols-12 w-full relative h-full pb-16  container  mx-auto xl:gap-4">
				<div class="reservation-content-calendar col-span-9 h-full relative">
					<el-calendar v-model="selectedDate">
						<template #dateCell="{data}">
							<div
								class="font-body overlay"
								:class="getYear(data.day) == currentYear && getMonth(data.day) == currentMonth && availableDays.includes(getDay(data.day)) ? 'is-available' : 'is-disabled'"
							>
								<div
									class="w-full h-full reg-button"
									@click="openRegFormModal(data.day)"
								>
									<p class="text-mdlg font-body tracking-wider day">{{ getDay(data.day) }}</p>
									<p class="text-base md:text-base font-body tracking-wider status">{{ getYear(data.day) == currentYear && getMonth(data.day) == currentMonth && availableDays.includes(getDay(data.day)) ? '尚可預約' : '預約額滿' }}</p>
								</div>
							</div>
						</template>
					</el-calendar>
				</div>
				<div class="content-text col-span-3 relative flex lg:flex-col items-end bottom-0 right-0 z-10 text-left pt-8 xl:pt-0">
					<div class="content-wrapper flex flex-col justify-between">
						<div class="header-text font-title flex  top-0 right-8 z-10 flex-col ">
							<h1 class="header-text-top text-left text-about leading-none hidden xl:block">Online</h1>
							<h1 class="header-text-bottom text-left text-about leading-none hidden xl:block mb-8">Booking</h1>
							<h2 class="content-text-title">線上預約</h2>
							<span class="content-decoration bg-white"></span>
						</div>
						<p class="content-text-description text-md">請從日曆中挑選日期，<br />成功申請後，<br />將會寄送電子郵件通知到您的信箱。</p>

					</div>
				</div>
			</div>
		</section>
		<el-dialog
			:title="regForm.date + ' (' + dayOfWeekInZh + ')' + ' 古禮抓周預約'"
			v-model="openRegForm"
			:width="dialogWidth"
		>
			<el-form
				label-width="120px"
				ref="regFormRef"
				:model="regForm"
				:rules="formRule"
				label-position="left"
			>
				<el-form-item
					label="預約日期"
					prop="date"
					v-show="false"
				>
					<el-input
						v-model="regForm.date"
						disabled
					></el-input>
				</el-form-item>
				<el-form-item
					label="幾胞胎"
					prop="childrenNumber"
					v-show="formFilledStep == 0"
					class="mb-0"
				>
					<el-select
						v-model="regForm.childrenNumber"
						placeholder="請選擇幾胞胎"
					>
						<el-option
							key=1
							label="單胞胎"
							value=1
						></el-option>
						<el-option
							key=2
							label="雙胞胎"
							value=2
						></el-option>
						<el-option
							key=3
							label="三胞胎"
							value=3
						></el-option>
					</el-select>
				</el-form-item>
				<p
					v-show="formFilledStep == 0"
					class="text-left ml-4 mt-4 mb-8"
				>(是幾胞胎不是生幾胎)</p>
				<el-form-item
					label="尚餘時段"
					prop="hourCode"
					v-show="formFilledStep == 0"
				>
					<el-select
						v-model.number="regForm.hourCode"
						placeholder="請選擇時段"
					>
						<template
							v-for="hour in workingHours"
							:key="hour.id"
						>
							<template v-if="availableHours.includes(hour.code)">
								<el-option
									:key=hour.code
									:label=hour.value
									:value=hour.code
								></el-option>
							</template>
						</template>
					</el-select>
				</el-form-item>
				<el-form-item
					label="選擇方案"
					prop="ceremonyPlan"
					v-show="formFilledStep == 0"
				>
					<el-select
						v-model="regForm.ceremonyPlan"
						placeholder="請選擇方案"
					>
						<el-option
							key="weekday"
							label="平日方案"
							value="weekday"
							v-if="!isWeekend"
						></el-option>
						<el-option
							key="holiday"
							label="假日方案"
							value="holiday"
							v-if="isWeekend"
						></el-option>
						<el-option
							key="whole"
							label="包場方案"
							value="whole"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="家長姓名"
					prop="parentName"
					v-show="formFilledStep == 1"
				>
					<el-input v-model="regForm.parentName"></el-input>
				</el-form-item>
				<el-form-item
					label="家長電話"
					prop="phoneNumber"
					v-show="formFilledStep == 1"
				>
					<el-input v-model="regForm.phoneNumber"></el-input>
				</el-form-item>
				<el-form-item
					label="寶貝姓名"
					prop="childrenName"
					v-show="formFilledStep == 1"
				>
					<el-input v-model="regForm.childrenName"></el-input>
				</el-form-item>
				<el-form-item
					label="寶貝性別"
					prop="gender"
					v-show="formFilledStep == 1"
				>
					<el-select
						v-model="regForm.gender"
						placeholder="請選擇性別"
					>
						<el-option
							key="male"
							label="男生"
							value="male"
						></el-option>
						<el-option
							key="female"
							label="女生"
							value="female"
						></el-option>
						<el-option
							key="both"
							label="龍鳳"
							value="both"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="寶貝生日"
					prop="childBirthdate"
					v-show="formFilledStep == 1"
				>
					<el-date-picker
						type="date"
						placeholder="請選擇滿一歲的國曆生日日期"
						v-model="regForm.childBirthdate"
						style="width: 100%;"
						:default-value="previousYearDate"
					></el-date-picker>
				</el-form-item>
				<el-form-item
					label="預計參加人數"
					prop="attendantNumber"
					v-show="formFilledStep == 1"
				>
					<el-input
						type="number"
						placeholder="概略即可"
						v-model.number="regForm.attendantNumber"
					></el-input>
				</el-form-item>
				<el-form-item
					label="LINE ID"
					prop="lineId"
					v-show="formFilledStep == 1"
				>
					<el-input v-model="regForm.lineId"></el-input>
				</el-form-item>
				<el-form-item
					label="信箱"
					prop="email"
					v-show="formFilledStep == 1"
				>
					<el-input
						type="email"
						v-model="regForm.email"
					></el-input>
				</el-form-item>

				<el-form-item
					label="加購項目"
					prop="buyMore"
					v-show="formFilledStep == 2"
				>
					<el-select
						v-model="regForm.buyMore"
						placeholder="請選擇加購項目"
						multiple
					>
						<el-option
							key="poem"
							label="藏頭詩(500元/幅)"
							value="poem"
						></el-option>
						<el-option
							key="calendar"
							label="客製化桌曆(360元/個)"
							value="calendar"
						></el-option>
						<el-option
							key="cakeStrawberry"
							label="生日蛋糕-莓好氣氛(880元)"
							value="cakeStrawberry"
						></el-option>
						<el-option
							key="cakeTaro"
							label="生日蛋糕-芋見富貴(880元)"
							value="cakeTaro"
						></el-option>
						<el-option
							key="cakeFruits"
							label="生日蛋糕-鼠於水果(980元)"
							value="cakeFruits"
						></el-option>
						<el-option
							key="cakeCheese"
							label="生日蛋糕-經典乳酪(399元)"
							value="cakeCheese"
						></el-option>
						<el-option
							key="clothes"
							label="古裝(150元/人)-寶貝虎裝無需另外付費"
							value="clothes"
						></el-option>
					</el-select>
				</el-form-item>
				<img
					v-show="formFilledStep == 2"
					src="../assets/img/reservation-buymore.svg"
					alt=""
				>
			</el-form>
			<template #footer>
				<template v-if="formFilledStep == 0">
					<span class="dialog-footer">
						<el-button @click="openRegForm = false; resetForm">取消</el-button>
						<el-button
							type="primary"
							:disabled="!completeFirstFormFilled"
							@click="nextForm(1)"
						>填寫詳細資料</el-button>
					</span>
				</template>
				<template v-if="formFilledStep == 1">
					<span class="dialog-footer">
						<el-button @click="formFilledStep = 0">回上一步</el-button>
						<el-button
							type="primary"
							@click="nextForm(2)"
						>選擇加購項目</el-button>
					</span>
				</template>
				<template v-if="formFilledStep == 2">
					<span class="dialog-footer">
						<el-button @click="formFilledStep = 1">回上一步</el-button>
						<el-button
							type="primary"
							@click="submitForm"
						>送出表單</el-button>
					</span>
				</template>
			</template>
		</el-dialog>
	</div>

</template>


<script>
import {
	ref,
	reactive,
	onMounted,
	onBeforeUnmount,
	watch,
	watchEffect,
} from "vue";
import { fetchApi } from "@/utils/fetchApi";
import { ElMessage } from "element-plus";
import dayjs from "dayjs";
import service from "../utils/request";

export default {
	name: "reservation",
	setup() {
		const date = new Date();
		date.setHours(8, 0, 0, 0);

		const selectedDate = ref(date);
		const availableDays = ref([]);
		const availableHours = ref([]);
		watch(selectedDate, async (selectedDate, oldDate) => {
			const oldMonth = oldDate.getMonth() + 1;
			const month = selectedDate.getMonth() + 1;
			const year = selectedDate.getFullYear();
			if (oldMonth != month) {
				const data = await fetchApi.registerDaysAvailable(year, month);
				availableDays.value = data.availableDays;
				currentMonth.value = data.month;
				currentYear.value = data.year;
			}
		});
		// const isoDate = selectedDate.value.toISOString();

		const getMonth = (isoDate) =>
			parseInt(isoDate.split("-").slice(1).join());
		const getDay = (isoDate) =>
			parseInt(isoDate.split("-").slice(2).join());
		const getYear = (isoDate) =>
			parseInt(isoDate.split("-").slice(0).join());
		const currentMonth = ref(0);
		const currentYear = ref(0);
		currentMonth.value = date.getMonth() + 1;
		currentYear.value = date.getFullYear();

		const getAvailableDays = async () => {
			const date = dayjs(selectedDate.value).format().split("T")[0];
			const year = date.split("-")[0];
			const month = date.split("-")[1];
			const data = await fetchApi.registerDaysAvailable(year, month);
			availableDays.value = data.availableDays;
		};

		const getAvailableHours = async () => {
			const date = dayjs(regForm.date).format().split("T")[0];
			const year = date.split("-")[0];
			const month = date.split("-")[1];
			const day = date.split("-")[2];
			regForm.year = year;
			regForm.month = month;
			regForm.day = day;
			const hours = await fetchApi.registerHoursAvailable(
				year,
				month,
				day
			);
			availableHours.value = hours.hourCode;
		};
		const dialogWidth = ref("80%");
		const windowWidth = ref(window.innerWidth);

		const onResize = () => {
			windowWidth.value = window.innerWidth;
		};

		onMounted(async () => {
			getAvailableDays();
			window.addEventListener("resize", onResize);
		});

		const dayOfWeekInZh = ref(null);

		// form related
		const workingHours = [
			{ code: 0, value: "09:00-10:15" },
			{ code: 1, value: "10:30-11:45" },
			{ code: 2, value: "12:00-13:15" },
			{ code: 3, value: "13:30-14:45" },
			{ code: 4, value: "15:00-16:15" },
			{ code: 5, value: "16:30-17:45" },
			{ code: 6, value: "18:00-19:15" },
		];
		const formFilledStep = ref(0);
		const completeFirstFormFilled = ref(false);
		const openRegForm = ref(false);
		const regFormRef = ref(null);
		const regFormInitialState = {
			year: "",
			month: "",
			day: "",
			date: "",
			hourCode: "",
			childrenNumber: "",
			ceremonyPlan: "",
			parentName: "",
			phoneNumber: "",
			cellphoneNumber: "",
			childrenName: "",
			gender: "",
			childBirthdate: "",
			attendantNumber: "",
			lineId: "",
			email: "",
			buyMore: [],
		};
		const regForm = reactive({
			...regFormInitialState,
		});
		const formRule = {
			date: [{ required: true, message: "請輸入日期", trigger: "blur" }],
			hourCode: [
				{ required: true, message: "請輸入時段", trigger: "blur" },
			],
			childrenNumber: [
				{
					required: true,
					message: "請輸入幾胞胎",
					trigger: "blur",
				},
			],
			ceremonyPlan: [
				{
					required: true,
					message: "請輸入方案",
					trigger: "blur",
				},
			],
			parentName: [
				{
					required: true,
					message: "請輸入家長姓名",
					trigger: "blur",
				},
			],
			phoneNumber: [
				{
					required: true,
					message: "請輸入家長電話，必須是數字",
					trigger: "blur",
				},
			],
			cellphoneNumber: [
				{
					required: true,
					message: "請輸入備用電話，必須是數字",
					trigger: "blur",
				},
			],
			childrenName: [
				{
					required: true,
					message: "請輸入小朋友姓名",
					trigger: "blur",
				},
			],
			gender: [
				{ required: true, message: "請輸入性別", trigger: "blur" },
			],
			childBirthdate: [
				{
					required: true,
					message: "請輸入小朋友生日",
					trigger: "blur",
				},
			],
			attendantNumber: [
				{
					type: "number",
					required: true,
					message: "請輸入參加人數，必須是數字",
					trigger: "blur",
				},
			],
			lineId: [
				{ required: true, message: "請輸入line ID", trigger: "blur" },
			],
			email: [
				{
					type: "email",
					required: true,
					message: "請輸入有效的email",
					trigger: "blur",
				},
			],
		};
		const resetForm = () => {
			Object.assign(regForm, regFormInitialState);
		};

		const isWeekend = ref(false);

		const checkWeekend = (date) => {
			const dayOfWeek = new Date(date).getDay();
			if (dayOfWeek == 6 || dayOfWeek == 0) isWeekend.value = true;
			else isWeekend.value = false;
			switch (dayOfWeek) {
				case 0:
					dayOfWeekInZh.value = "日";
					break;
				case 1:
					dayOfWeekInZh.value = "一";
					break;
				case 2:
					dayOfWeekInZh.value = "二";
					break;
				case 3:
					dayOfWeekInZh.value = "三";
					break;
				case 4:
					dayOfWeekInZh.value = "四";
					break;
				case 5:
					dayOfWeekInZh.value = "五";
					break;
				case 6:
					dayOfWeekInZh.value = "六";
					break;
				default:
					"非日期";
			}
		};
		const openRegFormModal = (date) => {
			openRegForm.value = true;
			resetForm();
			regForm.date = date;
			getAvailableHours();
			checkWeekend(date);
		};

		watchEffect(() => {
			if (
				regForm.childrenNumber &&
				regForm.hourCode.toString() &&
				regForm.ceremonyPlan
			) {
				completeFirstFormFilled.value = true;
			} else {
				completeFirstFormFilled.value = false;
			}
		});

		watchEffect(() => {
			if (windowWidth.value > 1024) {
				dialogWidth.value = "50%";
			} else {
				dialogWidth.value = "80%";
			}
		});

		const nextForm = (stepId) => {
			if (completeFirstFormFilled.value && stepId == 1)
				formFilledStep.value = 1;
			if (formFilledStep.value == 1 && stepId == 2) {
				regFormRef.value.validate((valid) => {
					if (valid) formFilledStep.value = 2;
					else return;
				});
			}
		};

		const submitForm = () => {
			if (regForm == null) return;
			if (regFormRef.value != null) {
				regFormRef.value.validate((valid) => {
					if (valid) {
						service
							.post(`/reservation`, {
								...regForm,
							})
							.then((res) => {
								service
									.post("/registertime", {
										year: regForm.year,
										month: regForm.month,
										day: regForm.day,
										hourCode: regForm.hourCode,
										reservationId: res.id,
									})
									.then((res) => {
										service
											.get(
												`/reservation/send-mail/${res.reservationId}`
											)
											.then(() =>
												ElMessage.success(
													"預約確認信寄送成功"
												)
											)
											.catch(() =>
												ElMessage.error(
													"預約確認信寄送失敗"
												)
											);
										resetForm();
										openRegForm.value = false;
										completeFirstFormFilled.value = false;
										formFilledStep.value = 0;
										ElMessage.success("表單送出成功");
									})
									.catch(() => {
										completeFirstFormFilled.value = false;
										formFilledStep.value = 0;
										ElMessage.error("時間預定失敗");
									});
							})
							.catch(() =>
								ElMessage.error("表單送出失敗，請填妥所有項目")
							);
					}
				});
			}
		};

		const previousYearDate = new Date(
			new Date().setFullYear(new Date().getFullYear() - 1)
		);

		onBeforeUnmount(() => {
			window.removeEventListener("resize", onResize);
		});

		return {
			selectedDate,
			availableDays,
			availableHours,
			getDay,
			getMonth,
			getYear,
			currentMonth,
			currentYear,
			openRegForm,
			openRegFormModal,
			regFormRef,
			regForm,
			formFilledStep,
			completeFirstFormFilled,
			submitForm,
			resetForm,
			formRule,
			nextForm,
			workingHours,
			dialogWidth,
			previousYearDate,
			isWeekend,
			dayOfWeekInZh,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/styles.scss";
.content {
	&-wrapper {
		width: 100%;
		padding: 3rem;
		height: 100%;
		background: url("../assets/img/reservation-logo.svg") center no-repeat;
		background-color: #1c7fc1;
		background-size: 120%;
		@media (max-width: 1024px) {
			background: #1c7fc1;
		}
	}
	&-text {
		&-title {
			@apply text-white text-xlxl font-medium leading-relaxed tracking-widest;
		}
		&-description {
			@apply text-white font-header;
		}
	}
}
.reservation {
	&-top-block {
		height: auto;
	}
}
// disable all .current pointer-events
::v-deep(.current) {
	position: relative;
	padding: 0;
	pointer-events: none;
}
// ::v-deep(.el-calendar .next) {
// 	display: none;
// }
::v-deep(.el-calendar .prev) {
	.overlay {
		background: initial;
		position: relative;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
	.status {
		display: none;
	}
}
::v-deep(.el-calendar .current) {
	.overlay {
		background: initial;
		position: relative;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}
::v-deep(.el-form-item__content) {
	display: flex;
}
::v-deep(.el-calendar-day .day) {
	@apply font-header;
	font-weight: bold;
}
</style>
<style lang="scss">
@import "../assets/scss/_variables.scss";
.el-calendar {
	background: rgba(255, 255, 255, 0.6);
	&__header {
		@apply bg-blue text-white;
	}
	&__title {
		@apply text-white text-md font-bold font-header;
	}
	&__button-group {
		.el-button {
			&:nth-child(1),
			&:nth-child(3) {
				opacity: 0.8;
				@apply text-about;
			}
			&:nth-child(2) {
				@apply text-blue;
			}
		}
	}
	thead th {
		@apply text-md;
		&:nth-child(1) {
			@apply text-blue;
		}
	}
	td {
		border: none;
	}
	&-table tr td:first-child {
		border: none;
	}
	&-table tr:first-child td {
		border: none;
	}
	.next {
		display: table-cell !important;
		position: relative;
		padding: 0;
		pointer-events: none;
		.el-calendar-day {
			border: 1px solid $gray2;
		}
		.is-disabled {
			background: transparent !important;
		}
		.font-body {
			display: none;
		}
	}
}
.prev,
.next,
.current {
	padding: 2px !important;
}

.el-calendar-day {
	padding: 0 !important;
	border: 1px solid $gray2;
	&:hover {
		background: transparent !important;
	}
	// disable .is-disabled child's pointer-events
	.reg-button {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.is-disabled {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		.reg-button {
			background: rgba(210, 196, 170, 0.4);
			color: #929292;
		}
	}
	// others are able to select
	.is-available {
		pointer-events: all;
		.reg-button {
			background: rgba(193, 220, 248, 0.4);
			color: #b1d7ff;
		}
	}
}
.is-selected {
	.el-calendar-day {
		.is-available {
			pointer-events: all;
			.reg-button {
				background: $blue;
				color: $white;
			}
		}
	}
}
.el-dialog__title {
	@apply text-blue font-bold font-body;
}
</style>